import axios from 'axios';
import { Message } from 'element-ui';
import global from '../common/global'//引用模块进来
const ins = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,//请求地址连接
    headers: {
      'Content-Type':'application/json'
    },
    responseType: 'json',
})
function handleToken(code) {
    if (global.TokenInvalidFlag == 0) {
        switch (code) {
            case 100: // 缺失令牌
                Message.error("缺失登录信息，请重新登录")
                break;
            case 101: // 令牌错误
                Message.error("登录令牌错误，请重新登录")
                break;
            case 102: // 令牌过期
                Message.error("登录异常，请重新登录")
                break;
            case 105: // 无效令牌
                Message.error("登录状态已失效，请重新登录")
                break;
            case 103: // PHP版本客户添加
                Message.error("此接口功能已经关闭，如有问题请联系管理员")
                break;
            case 403: //非法用户
                Message.error("登录信息异常，请重新登录")
                break;
        }
        global.TokenInvalidFlag = 1;
        sessionStorage.clear(); //前台清空session
        setTimeout(function () {
            location.href = '/'
        }, 1500)
    }
}
// 请求拦截
ins.interceptors.request.use(config => {
    return config;
})

// 响应拦截
ins.interceptors.response.use(res => {
    // if (res) {
    //     switch (res.data.code) {
    //         case 200: // 成功
    //             global.TokenInvalidFlag = 0;
    //             break;
    //         case 100: // 缺失令牌
    //             handleToken(100)
    //             break;
    //         case 101: // 令牌错误
    //             handleToken(101)
    //             break;
    //         case 102: // 令牌过期
    //             handleToken(102)
    //             break;
    //
    //         case 105: // 无效令牌
    //             handleToken(105)
    //             break;
    //         case 403: //非法用户
    //             handleToken(403)
    //             break;
    //         default:
    //             break;
    //     }
    // }
    return res.data
})

export default ins